import React from "react"
import { Link, graphql } from "gatsby"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MediaItemTemplate from "../components/media-item"
import Pagination from "../components/pagination"
import Breadcrumbs from "../components/breadcrumb"

const ImageArchiveTemplate = ({ data, pageContext }) => {
  const title = "GTRI Media Library: Images"
  const totalPages = pageContext.totalPages
  // prettier-ignore
  const currentPath = pageContext.nextPageLink.substring(0, pageContext.nextPageLink.lastIndexOf("/"))
  const currentPage = pageContext.pageNumber
  const crumbs = [
    {
      title: "Archives",
      slug: "/archives"
    }
  ]

  return (
    <Layout>
      <SEO title={title} />
      <Row>
        <Col id="page-title" className="col col-12">
          <h1 className="title">{title}</h1>
        </Col>
      </Row>
      <Breadcrumbs crumbs={crumbs} />
      <Row id="content">
        <Col>
          <Row>
            <Col>
              <strong>{data.allMarkdownRemark.totalCount} image results</strong>
            </Col>
          </Row>
          <Row>
            {data.allMarkdownRemark.nodes.map(item => (
              <MediaItemTemplate key={item.frontmatter.mid} item={item} />
            ))}
          </Row>
          <Row>
            <Col>
              <Pagination
                currentPath={currentPath}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <Link to="/archives" className="highlight-link-yellow">
                Go back to the archives
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query ImageQuery($limit: Int, $skip: Int) {
    allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___title }
      filter: {
        fileAbsolutePath: { regex: "/media/.*\\\\.md$/" }
        frontmatter: { type: { eq: "image" } }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        frontmatter {
          name
          title
          mid
          path
          ext
          type
        }
        html
      }
    }
  }
`

export default ImageArchiveTemplate
